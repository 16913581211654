import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import { getExperienceById } from 'api/experiences'
import * as ExperienceKeys from '../reactQueryKeys/experiences'
import { MultiQueryResults, NON_TRIP_STALE_TIME } from './common'
import { nonNullable } from 'lib/array/arrayUtils'
import { getOffersById } from 'api/offer'
import * as OfferKeys from '../reactQueryKeys/offers'
import * as CruiseKeys from '../reactQueryKeys/cruises'
import { getCruiseOffer } from 'api/cruises'
import GeoContext from 'contexts/geoContext'
import { useContext } from 'react'
import * as RecommendationService from 'api/recommendations'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { Moment } from 'moment'
import * as RecommendationKeys from '../reactQueryKeys/recommendations'

export interface UseExperienceParams {
  experienceId: string | undefined
  currentCurrency: string
  currentRegionCode: string
}

export const useExperience = ({
  experienceId, currentCurrency, currentRegionCode,
}: UseExperienceParams): UseQueryResult<App.ExperienceOffer> => {
  return useQuery(
    ExperienceKeys.detail(currentCurrency, experienceId),
    async() => experienceId ?
      getExperienceById(experienceId, {
        currentCurrency,
        currentRegionCode,
      }) :
      Promise.resolve(undefined),
    {
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

interface UseExperiencesParams {
  currentCurrency: string
  experienceIds: Array<string>
  currentRegionCode: string
}

export const useExperiences = ({
  currentCurrency, experienceIds, currentRegionCode,
}: UseExperiencesParams): MultiQueryResults<App.ExperienceOffer> => {
  const results = useQueries({
    queries: experienceIds.map((experienceId) => ({
      queryKey: ExperienceKeys.detail(currentCurrency, experienceId),
      queryFn: () => getExperienceById(experienceId, {
        currentCurrency,
        currentRegionCode,
      }),
      staleTime: NON_TRIP_STALE_TIME,
    })),
  })

  return {
    data: nonNullable(results.map((result) => result.data)),
    isLoading: results.some((result) => result.isLoading),
    isSuccess: results.every((result) => result.isSuccess),
    results,
  }
}

export const useOffersWithCollectionAPIBecauseToursV2AreNotReturnedFromTheOtherOne = (
  offerIds: Array<string>,
  region: string,
): UseQueryResult<Array<App.Offer | App.Tours.TourV2Offer | App.BedbankOffer>> => {
  return useQuery(
    OfferKeys.collection(region, offerIds),
    async() => {
      if (offerIds.length === 0) {
        return []
      }

      return await getOffersById(offerIds, region)
    },
    {
      staleTime: NON_TRIP_STALE_TIME,
      placeholderData: [],
    },
  )
}

export const useCruise = (
  cruiseId: string | undefined,
): UseQueryResult<App.CruiseOffer> => {
  const { currentRegionCode } = useContext(GeoContext)
  return useQuery(
    CruiseKeys.detail(cruiseId),
    () =>
      cruiseId ?
        getCruiseOffer(cruiseId, currentRegionCode) :
        Promise.resolve(undefined),
    {
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useCruises = (
  cruiseIds: Array<string>,
): MultiQueryResults<App.CruiseOffer> => {
  const { currentRegionCode } = useContext(GeoContext)
  const results = useQueries({
    queries: cruiseIds.map((cruiseId) => ({
      queryKey: CruiseKeys.detail(cruiseId),
      queryFn: () => getCruiseOffer(cruiseId, currentRegionCode),
    })),
  })

  return {
    data: nonNullable(results.map((result) => result.data)),
    isLoading: results.some((result) => result.isLoading),
    isSuccess: results.every((result) => result.isSuccess),
    results,
  }
}

export const useLPPRecommendations = (
  offerId: string | undefined,
  currentRegionCode: string,
  isLuxPlusMember: boolean,
  checkIn: Moment | undefined,
  checkOut: Moment | undefined,
): UseQueryResult<Array<App.Recommendation>> => {
  return useQuery(
    RecommendationKeys.detail(
      offerId,
      checkIn?.format(ISO_DATE_FORMAT) || '',
      checkOut?.format(ISO_DATE_FORMAT) || '',
    ),
    async() => {
      if (!checkIn) {
        const response = await RecommendationService.getRecommendations(
          offerId!,
          currentRegionCode,
          isLuxPlusMember,
        )
        return response.recommendations
      } else {
        const response = await RecommendationService.getCalendarRecommendations(
          offerId!,
          currentRegionCode,
          isLuxPlusMember,
          {
            checkIn: checkIn.format(ISO_DATE_FORMAT),
            duration: checkOut ? checkOut.diff(checkIn, 'day') : undefined,
          },
        )
        return response.recommendations
      }
    },
    {
      placeholderData: [],
      enabled: !!offerId,
    },
  )
}
