import React, { useCallback, forwardRef } from 'react'
import { connect } from 'react-redux'

import TourV2DurationText from './TourV2DurationText'
import BookmarkCard from '../BookmarkCardNew/BookmarkCard'
import BookmarkLocation from '../BookmarkCardNew/BookmarkLocation'
import PriceDetails, {
  buildDaysPriceLabel,
} from 'tripPlanner/components/ItemOfferPrice/PriceDetails'

import SecureWithDepositTagAndModal from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndModal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { getDepositPropertiesForTourV2 } from 'components/OfferList/OfferListTiles/YouMayAlsoLikeTile/Common/createYouMayAlsoLikeTileView'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2PurchasableOptionDetails from 'hooks/TourV2/useTourV2PurchasableOptionDetails'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import { max } from 'lib/array/arrayUtils'
import { ResponsiveImageOptions, getImageUrl } from 'lib/image/imageUtils'
import offerPageURL from 'lib/offer/offerPageURL'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

interface Props {
  currency: string
  purchasableOption: App.Tours.TourV2OfferPurchasableOption
  variation: App.Tours.TourV2OfferVariation
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary
  linkQuery?: URLSearchParams
  onClick?: (offer: App.Tours.TourV2OfferSummary | App.Tours.TourV2Offer) => void
  aspectRatio?: string
  source?: string
  className?: string
  target?: '_blank'
}

interface CardProps {
  purchasableOption: App.Tours.TourV2OfferPurchasableOption
  duration: number | null
  depositComponent: React.ReactNode
  currencyCode: string
  discountDisplayType?: React.ComponentProps<
    typeof PriceDetails
  >['discountDisplayType']
}

export function TourV2OfferCardPriceDetails({
  purchasableOption,
  duration,
  depositComponent,
  currencyCode,
  discountDisplayType = 'badge',
}: CardProps) {
  const isLuxPlusMember = useAppSelector(checkCanViewLuxPlusBenefits)
  const purchasableOptionDetails =
    useTourV2PurchasableOptionDetails(purchasableOption)
  if (!duration || !purchasableOptionDetails) return null
  return (
    <PriceDetails
      price={purchasableOptionDetails.pricePerPerson}
      luxPlusPrice={purchasableOptionDetails.memberPricePerPerson}
      discount={isLuxPlusMember ? purchasableOptionDetails.valuedAt?.memberSavingsPercentage : purchasableOptionDetails.valuedAt?.savingPercentage}
      currencyCode={currencyCode}
      priceLabel={buildDaysPriceLabel(duration, duration)}
      totalLabel="/person"
      discountDisplayType={
        purchasableOptionDetails.hasDiscount ? discountDisplayType : 'none'
      }
      roomOccupancy={purchasableOptionDetails.roomTypeName ?? undefined}
      depositComponent={depositComponent}
    />
  )
}

export const getItineraryDaysCount = (
  variation: App.Tours.TourV2OfferVariation,
): number | null => {
  if (!variation.itinerary) return null
  const lastDay = max(variation.itinerary, (item) => item.startDay)
  if (!lastDay) return null
  const daysCount = lastDay.startDay + lastDay.duration - 1
  return daysCount
}

const IMAGE_PARAMS: ResponsiveImageOptions = {
  width: '400px',
}

export const TourV2Card = forwardRef<HTMLAnchorElement, Props>(
  function TourV2Card(props: Props, ref) {
    const {
      purchasableOption,
      variation,
      offer,
      currency,
      linkQuery,
      onClick,
      aspectRatio,
      source,
      className,
      target,
    } = props

    const {
      startLocation,
      endLocation,
      hasTheSameStartAndEndLocation,
      countriesCount,
      durationInDaysCount,
      placesCount,
    } = useTourV2VariationDetails(variation)

    const image = variation.images[0]
    const imageUrl =
      image?.url ??
      (image.id &&
        getImageUrl(
          image.id,
          aspectRatio ? { aspectRatio, ...IMAGE_PARAMS } : IMAGE_PARAMS,
        ))
    const duration = getItineraryDaysCount(variation)

    const depositProps = getDepositPropertiesForTourV2(offer, currency)

    const depositComponent = depositProps.depositShouldDisplay ? (
      <SecureWithDepositTagAndModal
        depositAmountPercentage={depositProps.depositAmountPercentage}
        depositAmount={depositProps.depositAmount}
        depositType={depositProps.depositType}
        offerType={offer.type}
        depositThresholds={offer.depositThresholds}
      />
    ) : null

    const onTileClick = useCallback(() => {
      onClick?.(offer)
    }, [offer, onClick])

    const offerUrl = addQueryParamsToPath(offerPageURL(offer, linkQuery), {
      source,
    })

    return (
      <BookmarkCard
        ref={ref}
        className={className}
        title={offer.name}
        body=""
        additionalContent={
          <>
            <BodyText variant="small" weight="normal">
              Operated By {offer.brand.name}
            </BodyText>
            {!!durationInDaysCount && <TourV2DurationText
              durationInDaysCount={durationInDaysCount}
              placesCount={placesCount}
              countriesCount={countriesCount}
            />}
          </>
        }
        bookmarkButton={undefined}
        location={
          <BookmarkLocation
            startLocation={startLocation}
            endLocation={
              hasTheSameStartAndEndLocation ? startLocation : endLocation
            }
            variant="start_and_end"
          />
        }
        imageUrl={imageUrl}
        productType={offer.productType}
        offerPriceDetails={
          <TourV2OfferCardPriceDetails
            purchasableOption={purchasableOption}
            duration={duration}
            depositComponent={depositComponent}
            currencyCode={currency}
          />
        }
        offerUrl={offerUrl}
        onClick={onTileClick}
        urgencyLabels={[]} // TODO fix me
        isSoldOut={false} // TODO fix me
        target={target}
      />
    )
  },
)

interface ContainerProps {
  currency: string
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary
  linkQuery?: URLSearchParams
  onClick?: (offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary) => void
  aspectRatio?: string
  source?: string
  className?: string
  target?: '_blank'
}

const TourV2CardContainer = forwardRef<HTMLAnchorElement, ContainerProps>(
  function TourV2CardContainer(props: ContainerProps, ref) {
    const { cheapestPurchasableOption, cheapestVariation } =
      useCheapestOfTourV2Offer(props.offer)

    if (!cheapestPurchasableOption || !cheapestVariation) {
      return null
    }

    return (
      <TourV2Card
        className={props.className}
        purchasableOption={cheapestPurchasableOption}
        variation={cheapestVariation}
        currency={props.currency}
        offer={props.offer}
        onClick={props.onClick}
        linkQuery={props.linkQuery}
        source={props.source}
        target={props.target}
        ref={ref}
      />
    )
  },
)

function mapState(state: App.State) {
  return {
    currency: state.geo.currentCurrency,
  }
}

export default connect(mapState, null, null, { forwardRef: true })(
  TourV2CardContainer,
)
