import { rem } from 'polished'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

import PriceAndCtaOrSoldOut from './PriceAndCtaOrSoldOut'
import SimpleBookmarkButton from './SimpleBookmarkButton'
import TripGuard from '../../TripGuard/TripGuard'

import ClickableLink from 'components/Common/Clickable/ClickableLink'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { getDistanceLabel } from 'lib/customer/recommendationUtils'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import { SectionIdContext } from 'tripPlanner/contexts/SectionIdProvider'
import { useTripId } from 'tripPlanner/contexts/TripContext'
import { cardItemHoverAnimation } from 'tripPlanner/utils/css'

const Container = styled(ClickableLink)`
  background: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${(props) => props.theme.borderRadius.M};

  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${cardItemHoverAnimation}
`

const Image = styled.div`
  height: ${rem(173)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    height: ${rem(186)};
  }

  background-size: cover;
  background-position: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  padding: ${rem(16)} ${rem(16)} ${0} ${rem(16)};
`

const StickToBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${rem(8)} ${rem(16)} ${rem(16)} ${rem(16)};
  flex-grow: 1;
`

const TopBadges = styled.div`
  margin: ${rem(12)};
`

const BookmarkButtonBottomLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${rem(12)};

  & > * {
    transform: translateY(50%);
  }
`

export type BookmarkCardProps = {
  title?: string
  body?: string | React.ReactElement
  bodyLines?: number
  additionalContent?: React.ReactElement
  urgencyLabels?: Array<App.OfferUrgencyLabel>
  onClick?: () => void
  onBookmarkButtonClick?: () => void
  bookmarkButton: React.ReactElement | undefined
  isBookmarked?: boolean
  productType?: App.ProductType
  offerUrl: string | undefined
  imageUrl: string | undefined
  distance?: number | null
  distanceFrom?: string | null
  distanceUnit?: DistanceUnit
  location: React.ReactElement
  offerPriceDetails?: React.ReactElement
  isSoldOut?: boolean
  soldOutOfferId?: string
  className?: string
  target?: '_blank'
}

const BookmarkCard = React.forwardRef<HTMLAnchorElement, BookmarkCardProps>(
  (
    {
      title,
      body,
      bodyLines,
      additionalContent,
      urgencyLabels,
      productType,
      offerUrl,
      offerPriceDetails,
      imageUrl,
      bookmarkButton,
      isBookmarked,
      onClick,
      onBookmarkButtonClick,
      distance,
      distanceFrom,
      distanceUnit,
      location,
      isSoldOut,
      soldOutOfferId,
      className,
      target,
    },
    ref,
  ) => {
    const tripId = useTripId()

    const sectionId = useContext(SectionIdContext)

    const distanceText = useMemo(() => {
      if (!distanceFrom || !distance || !distanceUnit) {
        return null
      }
      return getDistanceLabel(distance, distanceFrom, distanceUnit)
    }, [distance, distanceUnit, distanceFrom])

    return (
      <Container
        ref={ref}
        to={offerUrl}
        onClick={onClick}
        className={className}
        data-testid="bookmark-card"
        target={target}
      >
        {imageUrl && (
          <Image style={{ backgroundImage: `url(${imageUrl})` }}>
            <TopBadges>
              {productType && <ProductTypeLabel productType={productType} />}
            </TopBadges>
            <BookmarkButtonBottomLayout>
              {bookmarkButton || (
                <TripGuard>
                  <SimpleBookmarkButton
                    isSaved={isBookmarked ?? false}
                    onClick={onBookmarkButtonClick}
                    label={isBookmarked ? 'Saved' : 'Save to trip'}
                  />
                </TripGuard>
              )}
            </BookmarkButtonBottomLayout>
          </Image>
        )}
        <Content>
          {!isSoldOut && <OfferLabels urgencyLabels={urgencyLabels} />}
          {isSoldOut && (
            <div>
              <UrgencyLabel variant="critical2">Sold out</UrgencyLabel>
            </div>
          )}
          {distanceText && (
            <BodyText
              variant="small"
              lineClamp={1}
              wrap="no-wrap"
              colour="neutral-three"
            >
              {distanceText}
            </BodyText>
          )}
          {location}
          <VerticalSpacer gap={4}>
            {title && (
              <Heading lineClamp={2} variant="heading6">
                {title}
              </Heading>
            )}
            {body && (
              <BodyText
                lineClamp={bodyLines ?? 2}
                variant="medium"
                weight="normal"
              >
                {body}
              </BodyText>
            )}
            {additionalContent}
          </VerticalSpacer>
        </Content>
        <StickToBottom>
          <PriceAndCtaOrSoldOut
            isSoldOut={isSoldOut ?? false}
            soldOutOfferId={soldOutOfferId}
            offerPriceDetails={offerPriceDetails}
            tripId={tripId}
            sectionId={sectionId}
          />
        </StickToBottom>
      </Container>
    )
  },
)

BookmarkCard.displayName = 'BookmarkCard'

export default BookmarkCard
