import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ViewMoreOffersCta from 'tripPlanner/components/Buttons/ViewMoreOffersCta'

const Wrapper = styled.div`
  margin-top: ${rem(8)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PriceAndCta = styled(Wrapper)`
  align-items: flex-end;
  flex-direction: row-reverse;
`

const SoldOutCta = styled(Wrapper)`
  align-items: center;
`

interface Props {
  isSoldOut: boolean
  soldOutOfferId?: string
  offerPriceDetails: React.ReactElement | undefined
  tripId: string
  sectionId: string
}

function PriceAndCtaOrSoldOut({
  isSoldOut,
  soldOutOfferId,
  offerPriceDetails,
  tripId,
  sectionId,
}: Props) {
  if (isSoldOut) {
    return (
      <SoldOutCta>
        <BodyText variant="large" weight="bold">
          Sold Out
        </BodyText>
        <ViewMoreOffersCta
          offerId={soldOutOfferId}
          sectionId={sectionId}
          tripId={tripId}
          showSearch
        />
      </SoldOutCta>
    )
  }

  // Row-reverse to right align the button even if the price doesn't exist.
  // Button is `nonInteractable` because this component gets rendered inside a link.
  return (
    <PriceAndCta>
      <TextButton kind="primary" size="medium" nonInteractable>
        View
      </TextButton>
      {offerPriceDetails}
    </PriceAndCta>
  )
}
export default PriceAndCtaOrSoldOut
