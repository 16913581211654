import React, { MouseEventHandler, useCallback } from 'react'
import { DepositInfoModal } from 'components/Common/DepositInfo/DepositInfoModal'
import useToggle from 'hooks/useToggle'
import Clickable from '../Clickable'
import SecureWithDepositTag from './SecureWithDepositTag'

interface Props {
  pkgAvailableRate?: App.OfferAvailableRate;
  offerType: App.OfferType;
  depositAmountPercentage?: number
  depositThresholds?: App.DepositThreshold
  depositType?: App.Tours.V2OfferDepositType;
  depositAmount?: number;
  testId?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  isFlexibleCancellation?: boolean
  showDepositAmountPercentage?: boolean
}

function SecureWithDepositTagAndModal(props: Props) {
  const {
    pkgAvailableRate,
    offerType,
    depositAmountPercentage,
    depositThresholds,
    depositAmount,
    depositType,
    testId,
    onClick,
    isFlexibleCancellation,
    showDepositAmountPercentage,
  } = props
  const [isPolicyOpen, , openPolicy, closePolicy] = useToggle()

  const onOpenPolicy = useCallback<MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>>((e) => {
    openPolicy()
    onClick?.(e)
  }, [openPolicy, onClick])

  return (
    <div>
      <Clickable onClick={onOpenPolicy}>
        <SecureWithDepositTag testId={testId} depositAmountPercentage={showDepositAmountPercentage ? depositAmountPercentage : 0} />
      </Clickable>
      <DepositInfoModal
        isFlexibleCancellation={isFlexibleCancellation}
        pkgAvailableRate={pkgAvailableRate}
        isModalOpen={isPolicyOpen}
        offerType={offerType}
        onCloseModal={closePolicy}
        depositAmountPercentage={depositAmount ?? depositAmountPercentage}
        depositThresholds={depositThresholds}
        depositType={depositType}
        depositAmount={depositAmount}
      />
    </div>
  )
}

export default React.memo(SecureWithDepositTagAndModal)
