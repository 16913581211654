// import { rem } from 'polished'
import React, { useCallback, forwardRef, useContext } from 'react'
import { connect, ConnectedProps } from 'react-redux'
// import styled from 'styled-components'

import TripGuard from '../../TripGuard/TripGuard'
import BookmarkCard from '../BookmarkCardNew/BookmarkCard'
import BookmarkLocation from '../BookmarkCardNew/BookmarkLocation'
import PriceDetails from 'tripPlanner/components/ItemOfferPrice/PriceDetails'

import SecureWithDepositTagAndModal from 'components/Common/SecureWithDeposit/SecureWithDepositTagAndModal'
import { getDepositPropertiesForOffer } from 'components/OfferList/OfferListTiles/YouMayAlsoLikeTile/Common/createYouMayAlsoLikeTileView'
import { getImageUrl, ResponsiveImageOptions } from 'lib/image/imageUtils'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import offerPageURL from 'lib/offer/offerPageURL'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { addQueryParamsToPath } from 'lib/url/searchUrlUtils'
import { getDefaultDepositAmountPercentage } from 'tripPlanner/../checkout/selectors/featureConfig/deposit'
import TourBookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton/TourBookmarkButton'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

function mapState(state: App.State) {
  return {
    currency: state.geo.currentCurrency,
    defaultDepositAmountPercentage: getDefaultDepositAmountPercentage(state),
  }
}

const connector = connect(mapState, null, null, { forwardRef: true })

export interface TourBookmarkCardOwnProps {
  offer: App.TourOfferSummary
  linkQuery?: URLSearchParams
  onClick?: (offer: App.OfferSummary) => void
  className?: string
  position?: number
  target?: '_blank'
}

export interface TourBookmarkCardProps
  extends ConnectedProps<typeof connector>,
    TourBookmarkCardOwnProps {
  aspectRatio?: string
  source?: string
}

interface PriceProps {
  depositComponent: React.ReactNode
  pkg: App.Package
  currencyCode: string
}

function TourOfferCardPriceDetails({
  pkg,
  depositComponent,
  currencyCode,
}: PriceProps) {
  const shouldShowDiscount = pkg?.shouldDisplayValue && pkg.value > pkg.price
  const discount = getValueOffPercent(pkg.value, pkg.price)
  const priceLabel = `${pkg.durationLabel.toLocaleLowerCase()} from`
  return (
    <PriceDetails
      price={pkg.price}
      discount={discount}
      currencyCode={currencyCode}
      priceLabel={priceLabel}
      totalLabel="/person"
      discountDisplayType={shouldShowDiscount ? 'badge' : 'none'}
      roomOccupancy={pkg.roomOccupancy}
      depositComponent={depositComponent}
    />
  )
}

const IMAGE_PARAMS: ResponsiveImageOptions = {
  width: '400px',
}

const TourBookmarkCard = forwardRef<HTMLAnchorElement, TourBookmarkCardProps>(
  function TourBookmarkCard(
    {
      offer,
      currency,
      linkQuery,
      onClick,
      className,
      defaultDepositAmountPercentage,
      aspectRatio,
      source,
      target,
      position,
    }: TourBookmarkCardProps,
    ref,
  ) {
    const { image, name, location } = offer
    const dispatchOfferListEvent = useContext(OfferListEventsContext)

    const urgencyLabels = offer.urgencyTags.filter(
      (label) => label.type !== 'left',
    )

    const onCardClick = useCallback(() => {
      onClick?.(offer)
      if (position !== undefined) {
        dispatchOfferListEvent({
          type: OfferListEvents.productClick,
          offer,
          position,
        })
      }
    }, [onClick, offer, position, dispatchOfferListEvent])

    const offerUrl = addQueryParamsToPath(offerPageURL(offer, linkQuery), {
      source,
    })

    const pkg = offer.lowestPricePackage

    if (!pkg) {
      return null
    }

    const imageUrl =
      image.url ??
      (image.id &&
        getImageUrl(
          image.id,
          aspectRatio ? { aspectRatio, ...IMAGE_PARAMS } : IMAGE_PARAMS,
        ))
    const offerAvailable = scheduleIsCurrent(offer.onlinePurchaseSchedule)
    const depositProps = getDepositPropertiesForOffer(
      offer,
      currency,
      defaultDepositAmountPercentage,
    )

    const depositComponent = depositProps.depositShown ? (
      <SecureWithDepositTagAndModal
        depositAmountPercentage={depositProps.depositAmountPercentage}
        depositThresholds={depositProps.depositThresholds}
        offerType={offer.type}
      />
    ) : undefined

    return (
      <BookmarkCard
        className={className}
        title={name}
        body="" // TODO fix me
        bookmarkButton={
          <TripGuard>
            <TourBookmarkButton offer={offer} />
          </TripGuard>
        }
        location={<BookmarkLocation startLocation={location} />}
        imageUrl={imageUrl}
        productType={offer.productType}
        offerPriceDetails={
          <TourOfferCardPriceDetails
            depositComponent={depositComponent}
            pkg={pkg}
            currencyCode={currency}
          />
        }
        offerUrl={offerUrl}
        onClick={onCardClick}
        urgencyLabels={urgencyLabels}
        isSoldOut={!offerAvailable}
        soldOutOfferId={offer.id}
        target={target}
        ref={ref}
      />
    )
  },
)

export default connector(TourBookmarkCard)
